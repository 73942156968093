<template>
    <div class="row flex-center min-vh-75 text-center">
        <div class="col-sm-10 col-md-8 col-lg-6 col-xxl-5">
            <div class="card">
                <div class="card-body p-4 p-sm-5">
                    <div class="display-1 text-300 fs-error">404</div>
                    <p class="lead mt-4 text-800 text-sans-serif font-weight-semi-bold">
                        The page you're looking for was not found.
                    </p>
                    <hr>
                    <p>
                        Make sure the address is correct and that the page hasn't moved. If you think this is a mistake, please contact support.
                    </p>
                    <router-link :to="{ path: '/' }" class="btn btn-primary btn-sm mt-3">
                        Back to Dashboard
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Error404',
    };
</script>
